import React from "react";
import { Button, Link, Stack, Tooltip, Typography } from "@mui/material";
import {
  CardErrorContent,
  CardWaitingContent,
} from "../../shared-components/Cards";
import { parseError } from "../../apiUtils";
import { LoadingButton } from "@mui/lab";
import {
  useBillingPortalMutation,
  useCheckoutSessionMutation,
} from "../../crud/subscriptions/hooks";
import { ProgramTable } from "./ProgramTable";
import { useAnalytics } from "use-analytics";
import { useProgram } from "../../crud/pricing/hooks";
import { BillingPlanEnum } from "../../api-client";
import { useIsOrgAdmin } from "../../crud/account/hooks";

type Props = {
  organizationId: number;
};

export const Subscription = ({ organizationId }: Props): React.ReactElement => {
  const analytics = useAnalytics();
  const isAdmin = useIsOrgAdmin(organizationId);
  const checkoutSessionMutation = useCheckoutSessionMutation();
  const billingPortalMutation = useBillingPortalMutation();
  const {
    data: activeProgram,
    isSuccess,
    isLoading,
    error,
  } = useProgram(organizationId);

  if (isLoading) {
    return <CardWaitingContent numRectangles={1} />;
  } else if (error || !isSuccess) {
    return <CardErrorContent error={parseError(error)} />;
  } else {
    return (
      <Stack spacing={2}>
        <ProgramTable organizationId={organizationId} />
        {activeProgram.tier === BillingPlanEnum.Enterprise && (
          <Typography>
            To manage this, please contact us at{" "}
            <Link href="mailto:support@coiled.io" target="_top">
              support@coiled.io
            </Link>
            {"."}
          </Typography>
        )}

        {activeProgram.tier === BillingPlanEnum.Payg && (
          <Stack>
            <Tooltip
              title={
                isAdmin
                  ? "Manage your subscription"
                  : "Contact your organization admin to manage this subscription"
              }
            >
              <span>
                <LoadingButton
                  disabled={!isAdmin}
                  variant="secondary"
                  // using isIdle here because we still want to be "loading" while
                  // redirecting
                  loading={!billingPortalMutation.isIdle}
                  onClick={() => {
                    analytics.track("manage-subscription-click", {
                      organizationId,
                    });
                    billingPortalMutation.mutateAsync(
                      {
                        organizationId,
                      },
                      {
                        onSuccess: (data) => {
                          window.location.href = data;
                        },
                      },
                    );
                  }}
                >
                  Manage
                </LoadingButton>
              </span>
            </Tooltip>
          </Stack>
        )}
        {activeProgram.tier === BillingPlanEnum.Free && (
          <Stack direction="row" spacing={2}>
            <Tooltip
              title={
                isAdmin
                  ? "Upgrade your subscription"
                  : "Contact your organization admin to upgrade your subscription"
              }
            >
              <span>
                <LoadingButton
                  // using isIdle here because we still want to be "loading" while
                  // redirecting
                  disabled={!isAdmin}
                  loading={!checkoutSessionMutation.isIdle}
                  onClick={() => {
                    analytics.track("upgrade-subscription-click", {
                      organizationId,
                    });
                    checkoutSessionMutation.mutateAsync(
                      {
                        organizationId,
                      },
                      {
                        onSuccess: (data) => {
                          window.location.href = data;
                        },
                      },
                    );
                  }}
                >
                  Upgrade
                </LoadingButton>
              </span>
            </Tooltip>
            <Button
              variant="tertiary"
              disableRipple
              href="https://coiled.io/pricing/"
              target="_blank"
            >
              Learn more
            </Button>
          </Stack>
        )}
      </Stack>
    );
  }
};
