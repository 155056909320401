/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingPlanEnum } from './BillingPlanEnum';
import {
    BillingPlanEnumFromJSON,
    BillingPlanEnumFromJSONTyped,
    BillingPlanEnumToJSON,
} from './BillingPlanEnum';

/**
 * This schema exists for backward compatibility and is just a weird view of an Organization
 * @export
 * @interface ProgramSchema
 */
export interface ProgramSchema {
    /**
     * 
     * @type {number}
     * @memberof ProgramSchema
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramSchema
     */
    coreLimitMembers: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramSchema
     */
    memberLimit: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramSchema
     */
    nodeLimit: number;
    /**
     * 
     * @type {BillingPlanEnum}
     * @memberof ProgramSchema
     */
    tier: BillingPlanEnum;
    /**
     * 
     * @type {number}
     * @memberof ProgramSchema
     */
    coiledCreditValue: number;
    /**
     * 
     * @type {Date}
     * @memberof ProgramSchema
     */
    renewDate: Date;
    /**
     * 
     * @type {number}
     * @memberof ProgramSchema
     */
    creditAllotment: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProgramSchema
     */
    spendLimitShouldStopInstances: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProgramSchema
     */
    programDollarsSpendLimit: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramSchema
     */
    organizationCreditLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramSchema
     */
    organizationName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramSchema
     */
    accountName?: string;
}

/**
 * Check if a given object implements the ProgramSchema interface.
 */
export function instanceOfProgramSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "coreLimitMembers" in value;
    isInstance = isInstance && "memberLimit" in value;
    isInstance = isInstance && "nodeLimit" in value;
    isInstance = isInstance && "tier" in value;
    isInstance = isInstance && "coiledCreditValue" in value;
    isInstance = isInstance && "renewDate" in value;
    isInstance = isInstance && "creditAllotment" in value;
    isInstance = isInstance && "spendLimitShouldStopInstances" in value;
    isInstance = isInstance && "programDollarsSpendLimit" in value;

    return isInstance;
}

export function ProgramSchemaFromJSON(json: any): ProgramSchema {
    return ProgramSchemaFromJSONTyped(json, false);
}

export function ProgramSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'coreLimitMembers': json['core_limit_members'],
        'memberLimit': json['member_limit'],
        'nodeLimit': json['node_limit'],
        'tier': BillingPlanEnumFromJSON(json['tier']),
        'coiledCreditValue': json['coiled_credit_value'],
        'renewDate': (new Date(json['renew_date'])),
        'creditAllotment': json['credit_allotment'],
        'spendLimitShouldStopInstances': json['spend_limit_should_stop_instances'],
        'programDollarsSpendLimit': json['program_dollars_spend_limit'],
        'organizationCreditLimit': !exists(json, 'organization_credit_limit') ? undefined : json['organization_credit_limit'],
        'organizationName': !exists(json, 'organization_name') ? undefined : json['organization_name'],
        'accountName': !exists(json, 'account_name') ? undefined : json['account_name'],
    };
}

export function ProgramSchemaToJSON(value?: ProgramSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'core_limit_members': value.coreLimitMembers,
        'member_limit': value.memberLimit,
        'node_limit': value.nodeLimit,
        'tier': BillingPlanEnumToJSON(value.tier),
        'coiled_credit_value': value.coiledCreditValue,
        'renew_date': (value.renewDate.toISOString()),
        'credit_allotment': value.creditAllotment,
        'spend_limit_should_stop_instances': value.spendLimitShouldStopInstances,
        'program_dollars_spend_limit': value.programDollarsSpendLimit,
        'organization_credit_limit': value.organizationCreditLimit,
        'organization_name': value.organizationName,
        'account_name': value.accountName,
    };
}

