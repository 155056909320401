import React, { useState } from "react";
import {
  useStaffOrganizations,
  useStaffOrganizationsNames,
} from "../../crud/organizations/hooks";
import { DataGridProCard } from "../../shared-components/Cards";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import {
  CreateBillingEventSchema,
  OrganizationStaffListSchema,
  BillingPlanEnum,
  ProgramSchema,
} from "../../api-client";
import { StaffOrganizationDetails } from "./OrganizationDetails";
import { Add, Download, Edit, Upload } from "@mui/icons-material";
import {
  useDowngradeToFreeMutation,
  useUpgradeToEnterpriseMutation,
} from "../../crud/pricing/hooks";
import { useQueryClient } from "react-query";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useCreateBillingEventMutation } from "../../crud/billingevents/hooks";
import { Link } from "react-router-dom";
import { Urls } from "../../domain/urls";

type changeProgramTarget = {
  organizationName: string;
  newTier: BillingPlanEnum;
  currentProgram: ProgramSchema;
};

type ConfirmProgramChangeDialogProps = {
  open: boolean;
  changeProgramTarget?: changeProgramTarget;
  onSuccess: () => void;
  onClose: () => void;
};

const ConfirmProgramChangeDialog = ({
  changeProgramTarget,
  open,
  onSuccess,
  onClose,
}: ConfirmProgramChangeDialogProps): React.ReactElement => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Program Change</DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to change{" "}
          <b>{changeProgramTarget?.organizationName}</b> from{" "}
          <b>{changeProgramTarget?.currentProgram.tier}</b> to{" "}
          <b>{changeProgramTarget?.newTier}</b>?
        </p>
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSuccess}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

type BillingDialogDetails = {
  organizationName: string;
  organizationId: number;
};
type BillingEventDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: CreateBillingEventSchema) => void;
  details: BillingDialogDetails;
};

const BillingEventDialog = ({
  open,
  onClose,
  details: { organizationName, organizationId },
  onSubmit,
}: BillingEventDialogProps): React.ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateBillingEventSchema>({
    defaultValues: {
      amountCredits: 0,
      note: "",
      organizationId,
    },
  });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle>Create Billing Adjustment</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            For <b>{organizationName}</b>
          </Typography>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Credits"
            helperText={
              errors.amountCredits ? errors.amountCredits.message : ""
            }
            error={Boolean(errors.amountCredits)}
            type="number"
            {...register("amountCredits", { required: "Credits is required" })}
          />
          <TextField
            label="Note"
            helperText={errors.note ? errors.note.message : ""}
            error={Boolean(errors.note)}
            {...register("note", { required: "Note is required" })}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export const OrganizationList = (): React.ReactElement => {
  const [orgName, setOrgName] = useState<string>("");
  const { data: organizations } = useStaffOrganizations({
    organization: orgName,
  });
  const { data: organizationNames } = useStaffOrganizationsNames({
    startsWith: orgName,
  });
  const queryClient = useQueryClient();
  const createBillingEventMutation = useCreateBillingEventMutation();
  const upgradeToEnterpriseMutation = useUpgradeToEnterpriseMutation();
  const downgradeToFreeMutation = useDowngradeToFreeMutation();
  const [programChangeOpen, setProgramChangeOpen] = useState(false);
  const [programChangeTier, setProgramChangeTier] = useState<
    changeProgramTarget | undefined
  >(undefined);
  const [createBillingEventProgram, setCreateBillingEventProgram] = useState<
    BillingDialogDetails | undefined
  >();

  const columns: GridColDef<OrganizationStaffListSchema>[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "tier",
      headerName: "Tier",
      valueGetter: (params) => {
        return params.row.activeProgram?.tier;
      },
    },
    {
      field: "creditAllotment",
      headerName: "Credit Allotment",
      valueGetter: (params) => {
        return params.row.activeProgram?.creditAllotment;
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        ...(params.row.activeProgram
          ? [
              <GridActionsCellItem
                showInMenu
                component={Link}
                key="edit-program"
                icon={<Edit />}
                // @ts-expect-error
                to={`/${Urls.CoiledStaff}/program/${params.row.activeProgram.id}`}
                label="Edit Limits"
                onClick={() => {
                  if (params.row.activeProgram) {
                    setCreateBillingEventProgram({
                      organizationName: params.row.name,
                      organizationId: params.row.activeProgram.id,
                    });
                  }
                }}
              />,
            ]
          : []),
        ...(params.row.activeProgram
          ? [
              <GridActionsCellItem
                showInMenu
                key="create-billing-event"
                icon={<Add />}
                label="Create Billing Event"
                onClick={() => {
                  if (params.row.activeProgram) {
                    setCreateBillingEventProgram({
                      organizationName: params.row.name,
                      organizationId: params.row.activeProgram.id,
                    });
                  }
                }}
              />,
            ]
          : []),
        ...(params.row.activeProgram?.tier !== BillingPlanEnum.Enterprise
          ? [
              <GridActionsCellItem
                showInMenu
                key="upgrade-to-enterprise"
                icon={<Upload />}
                label="Upgrade To Enterprise"
                onClick={() => {
                  if (params.row.activeProgram) {
                    setProgramChangeTier({
                      organizationName: params.row.name,
                      newTier: BillingPlanEnum.Enterprise,
                      currentProgram: params.row.activeProgram,
                    });
                    setProgramChangeOpen(true);
                  }
                }}
              />,
            ]
          : []),
        ...(params.row.activeProgram?.tier !== BillingPlanEnum.Free
          ? [
              <GridActionsCellItem
                showInMenu
                key="downgrade-to-free"
                icon={<Download />}
                label="Downgrade To Free Tier"
                onClick={() => {
                  if (params.row.activeProgram) {
                    setProgramChangeTier({
                      organizationName: params.row.name,
                      newTier: BillingPlanEnum.Free,
                      currentProgram: params.row.activeProgram,
                    });
                    setProgramChangeOpen(true);
                  }
                }}
              />,
            ]
          : []),
      ],
    },
  ];
  return (
    <>
      {createBillingEventProgram && (
        <BillingEventDialog
          open={Boolean(createBillingEventProgram)}
          onClose={() => setCreateBillingEventProgram(undefined)}
          onSubmit={(value) => {
            if (createBillingEventProgram) {
              createBillingEventMutation.mutate(value);
            }
            setCreateBillingEventProgram(undefined);
          }}
          details={createBillingEventProgram}
        />
      )}
      <ConfirmProgramChangeDialog
        onClose={() => setProgramChangeOpen(false)}
        open={programChangeOpen}
        changeProgramTarget={programChangeTier}
        onSuccess={() => {
          if (programChangeTier) {
            if (programChangeTier.newTier === BillingPlanEnum.Free) {
              downgradeToFreeMutation.mutate(
                {
                  organizationId: programChangeTier.currentProgram.id,
                },
                {
                  onSuccess: () =>
                    queryClient.invalidateQueries("staffOrganizations"),
                },
              );
            } else if (
              programChangeTier.newTier === BillingPlanEnum.Enterprise
            ) {
              upgradeToEnterpriseMutation.mutate(
                {
                  organizationId: programChangeTier.currentProgram.id,
                },
                {
                  onSuccess: () =>
                    queryClient.invalidateQueries("staffOrganizations"),
                },
              );
            }
          }
          setProgramChangeOpen(false);
        }}
      />
      <Stack spacing={2}>
        <Autocomplete
          autoHighlight
          id="combo-box-demo"
          options={organizationNames || []}
          freeSolo
          value={orgName}
          onChange={(_, value) => {
            if (typeof value === "string") {
              setOrgName(value);
            } else {
              setOrgName("");
            }
          }}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Organization name filter" />
          )}
        />
        <DataGridProCard
          dataGridProps={{
            rows: organizations?.items || [],
            columns,
            getDetailPanelHeight: () => "auto",
            getDetailPanelContent: (
              params: GridRowParams<OrganizationStaffListSchema>,
            ) => <StaffOrganizationDetails organizationId={params.row.id} />,
          }}
        />
      </Stack>
    </>
  );
};
