import React, { useContext, useMemo, useState } from "react";
import {
  Routes,
  Route,
  useParams,
  Navigate,
  useSearchParams,
  Link,
  useLocation,
} from "react-router-dom";
import { Clusters } from "./Clusters";
import { InfrastructureView } from "./Infrastructure";
import { InformationView } from "./Information";
import { Page } from "../../shared-components/Page";
import { Urls } from "../../domain/urls";
import { ClusterContext } from "./context";
import { AnalyticsView } from "./Analytics";
import { NavTabs } from "../../shared-components/NavTabs";
import { Box, Card, Tooltip } from "@mui/material";
import { LifecycleView } from "./Lifecycle";
import { useQuery } from "react-query";
import { ApiClient, parseError } from "../../apiUtils";
import { ClusterState } from "./Information/ClusterStateComponent";
import { MetricFlags } from "./MetricFlags";
import { UserContext } from "../../crud/user/context";
import { format } from "date-fns";
import { drawerWidth } from "../../Layouts/MainLayout";
import { useScopedContext } from "../../shared-components/ScopeSelector";
import { StringParam, useQueryParam } from "use-query-params";
import { useAccountDetails } from "../../crud/account/hooks";
import { WorkspaceContext } from "../../utils/context";

const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss 'UTC'xxx";

const ClusterRouter = () => {
  const clusterId = useParams().clusterId as string;
  const accountSlug = useParams().accountSlug as string;
  const [shouldFetch, setShouldFetch] = useState(true);
  const { user } = useContext(UserContext);
  const [, setScope] = useScopedContext();
  const { data: workspace, isSuccess: workspaceLoaded } = useAccountDetails({
    workspaceSlug: accountSlug,
  });
  const { isLoading, data, error } = useQuery(
    ["fetchCluster", clusterId, accountSlug],
    () =>
      ApiClient.declarativeViewsClusterClusterGetByIdForFrontend({
        pk: Number(clusterId),
        account: accountSlug,
      }),
    {
      refetchInterval: 5000,
      enabled: shouldFetch,
    },
  );

  const detailsBreadCrumbs = useMemo(
    () => [
      {
        text: "Clusters",
        link: `/${Urls.Clusters}?workspace=${
          data?.account.name || accountSlug
        }`,
        onClick: () => {
          setScope({
            type: "account",
            name: data?.account.name as string,
            id: data?.accountId as number,
            slug: data?.account.name as string,
          });
        },
      },
      {
        text: (
          <>
            <Tooltip
              title={
                <>
                  <div>{data?.name}</div>
                  {data?.created && (
                    <div>
                      <b>Created:</b>{" "}
                      {format(new Date(data?.created), DATE_TIME_FORMAT)}
                      <br />
                      <b>Finished:</b>{" "}
                      {data?.finishedAt &&
                        format(new Date(data?.finishedAt), DATE_TIME_FORMAT)}
                    </div>
                  )}
                  {user.isStaff && (
                    <div>
                      {data?.account.slug}/
                      <Link
                        to={`mailto:${data?.creator.fullName}<${data?.creator.email}>?subject=Coiled&bcc=emails-to-coiled-users@coiled.io`}
                      >
                        {data?.creator.username}
                      </Link>
                      <div>
                        <Link
                          to={`https://app.hubspot.com/reports-dashboard/9245528/view/8011523?globalSearchQuery=${encodeURI(data?.creator?.email || "")}`}
                        >
                          Hubspot
                        </Link>
                      </div>
                    </div>
                  )}
                </>
              }
            >
              <Box
                sx={(theme) => ({
                  maxWidth: `calc(100vw - ${drawerWidth + 560}px)`,
                  minWidth: "50px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                })}
              >
                {data?.name}
              </Box>
            </Tooltip>
            {data && <ClusterState state={data.currentState} />}
            <MetricFlags metricFlags={data?.metricFlags} />
          </>
        ),
      },
    ],
    [accountSlug, data, user.isStaff, setScope],
  );

  if (
    (data ? ["error", "stopped"].includes(data.currentState.state) : false) &&
    shouldFetch
  ) {
    setShouldFetch(false);
  }
  if (!workspaceLoaded) {
    return <div />;
  }
  return (
    <ClusterContext.Provider
      value={{
        error: error ? parseError(error) : undefined,
        loading: isLoading,
        clusterId,
        cluster: data,
      }}
    >
      <Page title="Cluster Details" breadCrumbs={detailsBreadCrumbs}>
        <Card>
          <WorkspaceContext.Provider value={workspace}>
            <NavTabs
              tabs={[
                {
                  label: "Information",
                  href: `/${Urls.Clusters}/${clusterId}/account/${accountSlug}/information`,
                },
                {
                  label: "Infrastructure",
                  href: `/${Urls.Clusters}/${clusterId}/account/${accountSlug}/infrastructure`,
                },
              ]}
            />
            <Routes>
              <Route
                path={`/`}
                element={
                  <IndexRedirect
                    accountSlug={accountSlug}
                    clusterId={clusterId}
                  />
                }
              />
              <Route path={`/lifecycle`} element={<LifecycleView />} />
              <Route
                path={`/infrastructure`}
                element={<InfrastructureView />}
              />
              <Route path={`/information`} element={<InformationView />} />
              <Route
                path={"/overview"}
                element={<LegacyClusterOverviewRedirect />}
              />
              <Route
                path={"/details"}
                element={<LegacyClusterDetailsRedirect />}
              />
              <Route
                path={`/analytics/:analyticsId`}
                element={<AnalyticsView />}
              />
            </Routes>
          </WorkspaceContext.Provider>
        </Card>
      </Page>
    </ClusterContext.Provider>
  );
};

const IndexRedirect = ({
  accountSlug,
  clusterId,
}: {
  accountSlug: string;
  clusterId: string;
}): React.ReactElement => {
  return (
    <Navigate
      to={`${Urls.Clusters}/${clusterId}/account/${accountSlug}/information`}
      replace
    />
  );
};

const LegacyClusterOverviewRedirect = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const { clusterId } = useParams();

  let url = `/clusters/${clusterId}/information`;

  if (searchParams.get("account")) {
    url += `?account=${searchParams.get("account")}`;
  }

  return <Navigate to={url} replace />;
};

const LegacyClusterDetailsRedirect = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const { clusterId } = useParams();

  let url = `/clusters/${clusterId}/infrastructure`;

  if (searchParams.get("account")) {
    url += `?account=${searchParams.get("account")}`;
  }

  return <Navigate to={url} replace />;
};

const SuperLegacyClusterRedirect = (): React.ReactElement => {
  const [globalScope] = useScopedContext();
  let account = "";
  if (globalScope.type === "account") {
    account = globalScope.slug;
  }
  const [accountSlug] = useQueryParam("account", StringParam);
  if (accountSlug) {
    account = accountSlug;
  }

  const path = useLocation();
  if (!account) {
    return <Navigate to={`/${Urls.Clusters}`} replace />;
  } else {
    const regex = /\/clusters\/(\d+).*/;
    // Replace the matched pattern with the new structure, inserting `someVar` in the specified position
    const newPath = path.pathname.replace(
      regex,
      `/clusters/$1/account/${account}/information`,
    );
    return <Navigate to={newPath} replace />;
  }
};

export const ClustersRouter = (): React.ReactElement => {
  const breadCrumbs = useMemo(() => [{ text: "Clusters" }], []);
  const [scope] = useScopedContext();
  return (
    <Routes>
      <Route
        path="/:clusterId/account/:accountSlug/*"
        element={<ClusterRouter />}
      />
      <Route path="/:clusterId/*" element={<SuperLegacyClusterRedirect />} />
      <Route
        path=""
        element={
          <Page
            title={`Coiled Dashboard (${scope.name})`}
            breadCrumbs={breadCrumbs}
          >
            <Clusters />
          </Page>
        }
      />
    </Routes>
  );
};
